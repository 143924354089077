<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Diego Alexander Villalba Cely                                 ###### -->
<!-- ###### @date: Abril 2023                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <section>
    <section class="d-flex mt-3">
      <v-text-field v-model="searchUser" class="me-3 ms-3" style="width: 50%" label="Buscar por usuario" dense
        outlined></v-text-field>
      <v-text-field v-model="searchContract" class="me-3" style="width: 50%" label="Buscar por contrato" dense
        outlined></v-text-field>

      <v-tooltip left color="success">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small color="success" class="me-3" @mousedown.prevent="agregarRelaciones()" fab v-bind="attrs" v-on="on">
            <v-icon>add</v-icon>
          </v-btn>
        </template>
        <span>Agregar relación</span>
      </v-tooltip>

    </section>

    <!-- modal para cargar relaxiones de forma masiva -->
    <v-dialog v-model="dialogAgregarRelaciones" persistent max-width="700px">
      <v-card>
        <v-tabs v-model="tab" background-color="blue darken-4" grow dark>
          <v-tabs-slider color="white"></v-tabs-slider>
          <v-tab>Agregar relaciones masivas </v-tab>
          <v-tab>Agregar relaciones uno a uno </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card-text class="pa-3">
              <div class="dropbox ma-1" v-show="verIputCSV">
                <input type="file" @change="lectorCSV()" ref="inputUpload" class="input-file" accept=".csv">
                <p :style="{ color: letraCSV }" class="mt-5">{{ textCSV }} </p>
              </div>
              <v-simple-table class="tablaRelaciones ma-1" v-show="verTabla" height="40vh">
                <template v-slot:default>
                  <thead>
                    <tr class="header">
                      <th> Usuario </th>
                      <th> Contrato </th>
                      <th> Validación </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, i) in csvTabla" :key="i">
                      <td>{{ data[0] }}</td>
                      <td>{{ data[1] }}</td>
                      <td v-if="data.length > 1">
                        <v-icon :color="data[2] == '' ? 'success' : 'error'">
                          {{ data[2] == '' ? 'check_circle' : 'cancel' }}
                        </v-icon>{{ data[2] }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div class="d-flex justify-end mt-5">
                <v-btn text color="error" @click="clear()">Cerrar</v-btn>
                <v-btn depressed :disabled="desactivarGuardar" @click="guardarRelaciones()" color="success">
                  Guardar </v-btn>
              </div>
            </v-card-text>
          </v-tab-item>

          <v-tab-item>
            <v-card-text class="pa-3">
              <div class="d-flex mt-3">
                <v-overlay :absolute="absolute" :value="overlay">
                  <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                </v-overlay>
                <v-autocomplete v-model="usuario" style="width: 35%;" :items="usuariosItems" label="Usuarios" dense
                  outlined>
                </v-autocomplete>
                <span class="mt-2 mx-2">Relacionar con:</span>
                <v-autocomplete v-model="contratos" style="width: 35%;" :items="contratoItems" label="Contratos"
                  small-chips multiple dense outlined>
                </v-autocomplete>
              </div>
              <div class="d-flex justify-end">
                <v-btn text color="error" @click="clear()">Cerrar</v-btn>
                <v-btn depressed @click="guardarRelacionUnoHerinco()" color="success">
                  Guardar </v-btn>
              </div>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>

    <!-- tabla donde muestra busqueda de realaciones -->
    <v-data-table class="tablaRelaciones mx-3" height="59vh" :loading="cargandoDatos"
      :server-items-length="totalElementos" :headers="tableHeaders" :page="pagina" :items-per-page="itemsPorPagina"
      :items="listarUsuarioContratos" @update:items-per-page="(i) => itemsPorPagina = i" @update:page="(p) => pagina = p"
      :footer-props="footerProps" fixed-header>

      <!-- agrega chip de estado a cada una de las relaciones -->
      <template v-slot:[`item.ACT_ESTA`]="{ item }">
        <div class="d-flex align-center">
          <v-icon :color="item.ACT_ESTA === 'A' ? 'green darken-2' : 'red'">
            {{ item.ACT_ESTA === 'A' ? 'check_circle' : 'cancel' }}
          </v-icon>
          {{ item.ACT_ESTA === 'A' ? 'Activo' : 'Inactivo' }}
        </div>
      </template>

      <!-- agrega boton para cambiar estado a cada una de las relaciones -->
      <template v-slot:[`item.acciones`]="{ item }">
        <section class="d-flex">
          <v-tooltip bottom :color="item.ACT_ESTA === 'A' ? 'error' : 'success'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text icon :color="item.ACT_ESTA === 'A' ? 'error' : 'success'" @click="modalActivoDesactivo(item)"
                v-bind="attrs" v-on="on">
                <v-icon>{{ item.ACT_ESTA === 'A' ? 'disabled_by_default' : 'fact_check' }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.ACT_ESTA === 'A' ? 'Inactivar' : 'Activar' }}</span>
          </v-tooltip>
        </section>
      </template>

      <!-- Botón para ver la trazabilidad -->
      <template v-slot:[`item.trazabilidad`]="{ item }">
        <v-tooltip bottom color="blue">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text icon color="blue" @click="listarTrazabilidad(item)" v-bind="attrs" v-on="on">
              <v-icon>backup_table</v-icon></v-btn>
          </template>
          <span>Trazabilidad</span>
        </v-tooltip>
      </template>
      <template v-slot:footer.page-text="items">
        {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
      </template>
    </v-data-table>

    <!-- modal para confimar activar o desactivar relacion -->
    <v-dialog v-model="dialogEstado" max-width="360">
      <v-card>
        <v-card-title class="fondoHeder">
          {{
            relacionSeleccionada.ACT_ESTA === 'A' ? '¿Desea desactivar la relación?' : '¿Desea activar la relación?'
          }}
        </v-card-title>
        <div class="d-flex justify-end pa-3">
          <v-btn color="error" text @click="dialogEstado = false"> No </v-btn>
          <v-btn color="success" @click="actualizarEstadoHerinco(relacionSeleccionada, estadoRelacionActualizar)">
            Si </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <ModalTrazabilidad :trazabilidadData="trazabilidadData" :trazaDe="trazaDe" />

  </section>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>
import Papa from 'papaparse';
import { mapMutations, mapState } from "vuex";
import ModalTrazabilidad from '@/components/ModalTrazabilidad.vue';

export default {
  name: "UserXContract",
  components: { ModalTrazabilidad },

  data() {
    return {
      codigoEmpresa: 1,
      actualizoUsuario: "",
      dialogEstado: false,
      dialogAgregarRelaciones: false,
      verIputCSV: true,
      textCSV: "Arrastre o haga click en este recuadro para subir un archivo .CSV",
      letraCSV: "black",
      desactivarGuardar: true,
      cargandoDatos: false,
      listarUsuarioContratos: [],
      itemsPorPagina: 7,
      itemFilas: [7, 10, 15, 20],
      pagina: 1,
      totalElementos: 0,
      searchUser: "",
      searchContract: "",
      relacionSeleccionada: {},
      estadoRelacionActualizar: "",
      verTabla: false,
      validarData: null,
      usuario: "",
      contratos: [],
      csvTabla: [],
      csvData: [],
      usuariosItems: [],
      dataUsuarios: [],
      contratoItems: [],
      dataContratos: [],
      tab: null,
      footerProps: {
        "show-first-last-page": true,
        "items-per-page-text": "Ítems por página",
        "items-per-page-options": [7, 10, 20, 30],
        "show-current-page": true,
      },
      absolute: true,
      overlay: false,
      // los values del tableHeaders son los keys del json del initialize
      tableHeaders: [
        { text: "USUARIO", value: "NOM_USUA", sortable: false },
        { text: "CONTRATO ASOCIADO", value: "DES_CONT", sortable: false, },
        { text: "ESTADO", value: "ACT_ESTA", sortable: false, },
        { text: "ACCIONES", value: "acciones", sortable: false, },
        { text: "TRAZABILIDAD", value: "trazabilidad", align: "center", sortable: false, },
      ],
      trazabilidadData: [],
      trazaDe: ""
    };
  },
  computed: {
    ...mapState(["auth", "notify", "busy", "trazabilidad"]),
  },
  watch: {
    "searchUser": function () { this.getRelacionesHerinco(); },
    "searchContract": function () { this.getRelacionesHerinco(); },
    "itemsPorPagina": function () { this.getRelacionesHerinco(); },
    "pagina": function () { this.getRelacionesHerinco(); },
  },
  mounted() {
    this.actualizoUsuario = this.auth.username.toUpperCase();
    this.trazabilidad.visible = false;
    this.getUsuariosHerinco();
    this.trazabilidad.visible = false
    this.getContratoHerinco();
    this.getRelacionesHerinco();
  },
  methods: {
    ...mapMutations([
      "showSuccess",
      "showError"
    ]),
    agregarRelaciones() {
      this.letraCSV = "black";
      this.textCSV = "Arrastre o haga click en este recuadro para subir un archivo .CSV";
      this.getContratoHerinco();
      this.getUsuariosHerinco();
      this.dialogAgregarRelaciones = true;
    },
    // metodo para listar las relaciones
    getRelacionesHerinco() {
      this.listarUsuarioContratos = [];
      this.cargandoDatos = true;
      this.$http.get(`msa-administration/api/DpRusco/usuario-contrato`, {
        params: {
          codigoUsuario: this.searchUser,
          descripcion: this.searchContract,
          codigoEmpresa: this.codigoEmpresa,
          pageNumber: this.pagina - 1,
          pageSize: this.itemsPorPagina
        }
      }).then(({ data }) => {
        this.listarUsuarioContratos = data.content;
        this.totalElementos = data.totalElements;
        this.cargandoDatos = false;
      }).catch(err => console.log(err));
    },
    getContratoHerinco() {
      this.contratoItems = [];
      this.dataContratos = [];
      this.$http.get(`msa-administration/dpContr/contratos`)
        .then(({ data }) => {
          this.dataContratos = data;
          data.forEach(contrato => {
            if (contrato.codigoEmpresa == 1 && contrato.estado == "A") this.contratoItems.push({ text: contrato.nombre.trim().toUpperCase(), value: contrato.id })
          })
        })
        .catch(err => console.log(err));
    },
    getUsuariosHerinco() {
      this.usuariosItems = [];
      this.dataUsuarios = [];
      this.$http.get(`msa-pharmaceutical-services/api/HdUsua/usuarios-activos`).then(({ data }) => {
        this.dataUsuarios = data;
        data.forEach(usuario => { if (usuario.actEsta == "A") this.usuariosItems.push({ text: usuario.nomUsua.toUpperCase(), value: usuario.codUsua.trim().toUpperCase() }) });
      }).catch(err => console.log(err));
    },
    // metodo para cambiar el estado de una relacion
    actualizarEstadoHerinco(dataRelacion, estado) {
      if (estado != "A") {
        this.$http.put(`msa-administration/api/DpRusco/actualizar-estado/${dataRelacion.RMT_USCO}`, { actEsta: estado, actUsua: this.actualizoUsuario })
          .then(() => {
            this.showSuccess("El estado de la relación ha sido actualizado correctamente.");
            this.getRelacionesHerinco();
            this.dialogEstado = false;
          }).catch(err => this.showError(err));
      } else {
        const usuario = this.usuariosItems.find(item => item.text == dataRelacion.NOM_USUA);
        const contrato = this.contratoItems.find(item => item.text == dataRelacion.DES_CONT.trim());
        if (usuario && contrato) {
          this.$http.put(`msa-administration/api/DpRusco/actualizar-estado/${dataRelacion.RMT_USCO}`, { actEsta: estado, actUsua: this.actualizoUsuario })
            .then(() => {
              this.showSuccess("El estado de la relación ha sido actualizado correctamente.");
              this.getRelacionesHerinco();
              this.dialogEstado = false;
            }).catch(err => this.showError(err));
        } else {
          !usuario ? this.showError("La relación no se puede activar porque el usuario está inactivo") : this.showError("La relación no se puede activar porque el contrato está inactivo");
          this.dialogEstado = false;
        }
      }
    },
    modalActivoDesactivo(dataRelacion) {
      this.getContratoHerinco();
      this.getUsuariosHerinco();
      this.dialogEstado = true;
      this.relacionSeleccionada = dataRelacion;
      dataRelacion.ACT_ESTA === "A" ? this.estadoRelacionActualizar = "B" : this.estadoRelacionActualizar = "A";
    },

    async validarExistencia(contrato, usuario) {
      const existe = await this.$http.get(`msa-administration/api/DpRusco/valida-relacion/${contrato}/${usuario}`);
      return existe.data ? { existe: true } : { existe: false };
    },

    async lectorCSV() {
      this.csvTabla = [];
      const archivo = this.$refs.inputUpload.files[0];
      if (archivo.type != "text/csv") {
        this.letraCSV = "red";
        return this.textCSV = "Formato incorrecto, debe subir o arrastrar un archivo .CSV";
      }
      this.verIputCSV = false;
      this.verTabla = true;
      Papa.parse(archivo, {
        delimiter: ";",
        complete: ({ data }) => {
          data.forEach((fila) => {
            if (fila.length > 1) {
              const filaReducida = [fila[0], fila[1]];
              this.csvData.push(filaReducida);
            }
          });

          this.csvData.forEach(async (fila) => {
            fila[2] = "";
            const usuario = this.dataUsuarios.find(item => item.codUsua.trim().toUpperCase() == fila[0].trim().toUpperCase());
            const contrato = this.dataContratos.find(item => item.id == fila[1].trim());

            if (contrato) {
              fila[1] = contrato.nombre;
              if (contrato.codigoEmpresa != 1) {
                fila[2] += "- Contrato no pertenece a la empresa 1 ";
              } else if (contrato.estado == "B") {
                fila[2] += "- Contrato inactivo en Herinco ";
              }
            } else {
              fila[2] += "- Contrato no existe en Herinco";
            }
            if (usuario) {
              fila[0] = usuario.codUsua.toUpperCase();
              if (usuario.actEsta == "B") fila[2] += "- Usuario inactivo en Herinco";
            } else {
              fila[2] += "- Usuario no existe en Herinco";
            }
            if (usuario && contrato) {
              const respuesta = await this.validarExistencia(contrato.id, usuario.codUsua);
              if (respuesta.existe) fila[2] += "- La relación ya existe. "
            }

          });
          setTimeout(() => {
            const validarGuardar = this.csvData.find(valida => valida[2] != "");
            validarGuardar ? this.desactivarGuardar = true : this.desactivarGuardar = false;
            this.csvTabla = this.csvData;
          }, 300);
        },
      });
    },
    async guardarRelaciones() {
      for (const data of this.csvTabla) {
        if (data[0] != "" && data[1] != "") {
          this.overlay = true;
          const usuario = this.usuariosItems.find(item => item.value == data[0].toUpperCase());
          const contrato = this.contratoItems.find(item => item.text == data[1].trim().toUpperCase());
          !usuario ? data[0] = "Usuario erróneo" : data[0] = usuario.value;
          !contrato ? data[1] = "Contrato erróneo" : data[1] = contrato.value;
          try {
            await this.$http.post("msa-administration/api/DpRusco/guardar-relacion", {
              codEmpr: 1,
              codUsua: data[0],
              rmtCont: data[1],
              actEsta: "A",
              actUsua: this.actualizoUsuario
            });
          }
          catch (err) {
            this.showError(err);
          }
        }
      }
      this.overlay = false;
      this.clear();
      this.getRelacionesHerinco();
      this.showSuccess("Relaciones agregadas correctamente.");
    },
    async guardarRelacionUnoHerinco() {
      const validacionData = [];
      if (this.usuario != "" && this.contratos.length > 0) {
        for (const contrato of this.contratos) {
          this.overlay = true;
          const respuesta = await this.validarExistencia(contrato, this.usuario);
          if (respuesta.existe) validacionData.push({ rmtCont: contrato, codUsua: this.usuario });

          if (validacionData.length === 0) {
            try {
              await this.$http.post("msa-administration/api/DpRusco/guardar-relacion", {
                codEmpr: 1,
                codUsua: this.usuario.toUpperCase(),
                rmtCont: contrato,
                actEsta: "A",
                actUsua: this.actualizoUsuario
              });
            }
            catch (err) {
              this.showError(err);
            }
          }

        }
        if (validacionData.length > 0) {
          this.overlay = false;
          validacionData.forEach((res, index) => {
            setTimeout(() => {
              const contrato = this.contratoItems.find(item => item.value == res.rmtCont);
              const usuario = this.usuariosItems.find(item => item.value == res.codUsua.toUpperCase());
              this.showError(`La relación ${usuario.text} - ${contrato.text} ya existe`)
            }, index * 2000);
          })

        } else {
          this.overlay = false;
          this.clear();
          this.getRelacionesHerinco();
          this.showSuccess("Relaciones agregadas correctamente.");
        }

      }
      else {
        this.showError("Complete los dos campos");
      }
    },
    clear() {
      this.csvData = [];
      this.usuario = "";
      this.contratos = [];
      this.$refs.inputUpload.value = null;
      this.verTabla = false;
      this.verIputCSV = true;
      this.desactivarGuardar = true;
      this.dialogAgregarRelaciones = false;
    },
    listarTrazabilidad(relacion) {
      this.$http.get(`msa-administration/listarTraza/${relacion.RMT_USCO}`)
        .then(({ data }) => {
          data.forEach(element => {
            element.time = this.formatoFechaTrazabilidad(element.time);
            if (element.campo == 'estado_relacion') element.campo = 'Estado';
            if (element.campo == 'codigo_usuario') element.campo = 'Usuario';
            if (element.campo == 'nombre') element.campo = 'Contrato';
            if (element.anterior == 'A') element.anterior = 'Activo';
            if (element.anterior == 'B') element.anterior = 'Inactivo';
            if (element.nuevo == 'A') element.nuevo = 'Activo';
            if (element.nuevo == 'B') element.nuevo = 'Inactivo';
          })
          this.trazabilidad.visible = true;
          this.trazaDe = `${relacion.NOM_USUA} - ${relacion.DES_CONT}`
          this.trazabilidadData = data.reverse();
        }).catch(err => console.log(err));
    },
    formatoFechaTrazabilidad(dateTimeString) {
      const fecha = new Date(dateTimeString);
      const horas = fecha.getHours();
      const minutos = fecha.getMinutes();
      const ampm = horas >= 12 ? 'p.m.' : 'a.m.';
      const formatoHoras = horas % 12 || 12;
      const formatoMinutos = minutos < 10 ? `0${minutos}` : minutos;
      const formatoMes = (fecha.getMonth() + 1).toString().padStart(2, '0');
      const formatoDia = fecha.getDate().toString().padStart(2, '0');
      const formatoFechaHora = `${fecha.getFullYear()}/${formatoMes}/${formatoDia} ${formatoHoras.toString().padStart(2, '0')}:${formatoMinutos}${ampm}`;
      return formatoFechaHora;
    },
  },
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Style                                                       ###### -->
<!-- #################################################################################### -->
<style scoped>
.fondoHeder {
  background-color: #1867c0 !important;
  color: white;
}

::v-deep .tablaRelaciones div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .tablaRelaciones div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

th {
  color: black !important;
}

.dropbox {
  outline: 2px dashed grey;
  outline-offset: -10px;
  color: rgb(0, 0, 0);
  background: lightcyan;
  padding: 1rem;
  height: 100px;
  position: relative;
  cursor: pointer;
}

.dropbox p {
  margin-top: .7rem;
  text-align: center;
}

.input-file {
  opacity: 0;
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}
</style>